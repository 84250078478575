import { downloadFileWithUUID } from "helpers/downloadFileWithUUID";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { deletePackage } from "services/package";
import { mapCurrencyToSelect } from ".";
import { PACKAGE_TYPES } from "./AddEditPackageModal";
import { Delete, Download, Edit, Eye, PaymentClock } from "./icons";

export default function PackageTable({
	packages,
	setAddPaymentModal,
	addEditPaymentModal,
	setAddEditModal,
	addEditModal,
	fetchPackages,
	allCurrencies,
}) {
	const history = useHistory();

	return (
		<div className="px-3 our-border border-top-0 overflow-auto">
			<table
				className="custom-table back-office-table table table-striped mb-0"
				style={{ minWidth: "max-content" }}
			>
				<thead>
					<tr>
						<th>No</th>
						<th>Company Name</th>
						<th>Safa Key</th>
						<th>Country</th>
						<th>Package Name</th>
						<th>Package Price</th>
						<th>Currency</th>
						<th>Contract</th>
						<th>Visa Count</th>
						<th>Exp Date</th>
						<th>Added On</th>
						<th>Paid Amount</th>
						<th>Document</th>
						<th>Added by</th>
						<th>Remaining Amount</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{packages.map((pkg) => (
						<tr key={pkg.id}>
							<td>{pkg.id}</td>
							<td>{pkg.company}</td>
							<td>{pkg.safa_key}</td>
							<td>{pkg.country}</td>
							<td>{pkg.package}</td>
							<td>{pkg.package_price}</td>
							<td>{pkg.currency.symbol}</td>
							<td>{pkg.contract}</td>
							<td>{pkg.visa_count}</td>
							<td>
								<p className="text-green">{pkg.start_date}</p>
								<p className="text-red">{pkg.end_date}</p>
							</td>
							<td>{moment(pkg.created_at).format("YYYY-MM-DD")}</td>
							<td>{pkg.amount_pay}</td>
							<td>
								{pkg?.files_id.length ? (
									<button
										className="bg-transparent"
										onClick={() => {
											pkg?.files_id.length !== 0 &&
												downloadFileWithUUID(
													pkg?.files_id[pkg?.files_id.length - 1]
												);
										}}
									>
										<Download />
									</button>
								) : null}
							</td>
							<td>{pkg.creator.name}</td>
							<td>{pkg?.amount_remain}</td>
							<td>
								<ShowForPermission
									type="and"
									permission={[
										"SafaVisa-Packages-View",
										"SafaVisa-Packages-List",
									]}
								>
									<button
										className="bg-transparent"
										onClick={() => history.push(`/package/${pkg.id}`)}
									>
										<Eye />
									</button>
								</ShowForPermission>
								<ShowForPermission
									type="and"
									permission={[
										"SafaVisa-Package-Payment-Add",
										"SafaVisa-Packages-List",
									]}
								>
									{parseFloat(pkg?.amount_pay) !==
									parseFloat(pkg?.package_price) ? (
										pkg.package_type !== "gift" ? (
											<button
												className="bg-transparent"
												onClick={() => {
													setAddPaymentModal({
														...addEditPaymentModal,
														open: true,
														data: {
															...addEditPaymentModal.data,
															safa_visa_package_id: pkg.id,
															company_name: pkg.company,
															package_price: pkg.package_price,
															amount_pay: pkg.amount_pay,
															batch_date: pkg?.batch_date || null,
														},
													});
												}}
											>
												<PaymentClock />
											</button>
										) : null
									) : null}
								</ShowForPermission>

								<ShowForPermission
									type="and"
									permission={[
										"SafaVisa-Packages-Edit",
										"SafaVisa-Packages-List",
									]}
								>
									<button
										className="bg-transparent"
										onClick={() => {
											setAddEditModal({
												...addEditModal,
												open: true,
												type: "edit",
												data: {
													...pkg,
													safa_key: {
														label: pkg.safa_key,
														value: pkg.safa_key,
														safa_key: pkg.safa_key,
														company_name: pkg.company,
														country: pkg.country,
													},
													currency: mapCurrencyToSelect(
														allCurrencies.filter(
															(currency) => currency.id === pkg.currency.id
														)
													)[0],
													start_date: moment(pkg.start_date),
													end_date: moment(pkg.end_date),
													amount_pay: pkg.amount_pay,
													package_type: PACKAGE_TYPES.find(
														(type) => type.value === pkg.package_type
													),
													visa_count: pkg.visa_count,
												},
											});
										}}
									>
										<Edit />
									</button>
								</ShowForPermission>

								<ShowForPermission
									type="and"
									permission={[
										"SafaVisa-Packages-Delete",
										"SafaVisa-Packages-List",
									]}
								>
									{pkg.amount_pay === 0 && (
										<button
											className="bg-transparent"
											onClick={() => {
												deletePackage(pkg.id).then(() => {
													fetchPackages();
												});
											}}
										>
											<Delete />
										</button>
									)}
								</ShowForPermission>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
