import { downloadFileWithUUID } from "helpers/downloadFileWithUUID";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { fetchPackage, fetchPaymentList } from "services/package";
import { Download } from "./icons";

export default function PackageView() {
	const [safaPackage, setSafaPackage] = useState(null);
	const [paymentList, setPaymentList] = useState([]);
	const { id } = useParams();
	const [noteModal, setNoteModal] = useState({
		isOpen: false,
		note: "",
	});

	const toggleNoteModal = () => {
		setNoteModal({ isOpen: !noteModal.isOpen, note: "" });
	};

	useEffect(() => {
		(async () => {
			const packageRes = await fetchPackage(id);
			setSafaPackage(packageRes.data.data);

			const paymentListRes = await fetchPaymentList({ package_id: id });
			setPaymentList(paymentListRes.data.data);
		})();
	}, [id]);

	return (
		<div>
			<h1>Package Details</h1>

			{safaPackage && (
				<>
					<div className="d-flex flex-column" style={{ gap: 20 }}>
						<div
							className="d-flex justify-content-between fs-5"
							style={{ color: "#4B465C", width: "100%", maxWidth: "300px" }}
						>
							<span>Company Name:</span>
							<span>{safaPackage?.company}</span>
						</div>

						<div
							className="d-flex justify-content-between fs-5"
							style={{ color: "#4B465C", width: "100%", maxWidth: "300px" }}
						>
							<span>Country:</span>
							<span>{safaPackage?.country}</span>
						</div>

						<div
							className="d-flex justify-content-between fs-5"
							style={{ color: "#4B465C", width: "100%", maxWidth: "300px" }}
						>
							<span>Safa key:</span>
							<span>{safaPackage?.safa_key}</span>
						</div>

						<div
							className="d-flex justify-content-between fs-5"
							style={{ color: "#4B465C", width: "100%", maxWidth: "300px" }}
						>
							<span>Package:</span>
							<span>{safaPackage?.package}</span>
						</div>

						<div
							className="d-flex justify-content-between fs-5"
							style={{ color: "#4B465C", width: "100%", maxWidth: "300px" }}
						>
							<span>Package Price:</span>
							<span>
								{safaPackage?.package_price} {safaPackage?.currency?.symbol}
							</span>
						</div>

						<div
							className="d-flex justify-content-between fs-5"
							style={{ color: "#4B465C", width: "100%", maxWidth: "300px" }}
						>
							<span>Payments:</span>
						</div>
					</div>

					<div className="px-3 our-border border-top-0 overflow-auto mt-3">
						<table
							className="custom-table back-office-table table table-striped mb-0"
							style={{ minWidth: 1250 }}
						>
							<thead>
								<tr>
									<th>NO</th>
									<th>Paid Amount</th>
									<th>Currency</th>
									<th>Added On</th>
									<th>Payment Method</th>
									<th>Installment Date</th>
									<th>Document</th>
									<th>Add By</th>
									<th>Note</th>
								</tr>
							</thead>

							<tbody>
								{paymentList?.map((payment, index) => (
									<tr key={payment.id}>
										<td>{index + 1}</td>
										<td>{payment?.amount}</td>
										<td>{payment?.currency?.symbol}</td>
										<td>{payment?.created_at}</td>
										<td>{payment?.payment_method?.name}</td>
										<td>{payment?.batch_date || "-"}</td>
										<td>
											{payment?.file_id ? (
												<button
													className="bg-transparent"
													onClick={() => downloadFileWithUUID(payment?.file_id)}
												>
													<Download />
												</button>
											) : null}
										</td>
										<td>{payment?.safa_visa_package?.creator?.name}</td>
										<td>
											{payment?.note && (
												<>
													<span className="mr-2">
														{payment?.note.split(" ").slice(0, 3).join(" ")}
														...
													</span>

													<button
														className="bg-transparent text-info fs-6"
														onClick={() =>
															setNoteModal({
																isOpen: true,
																note: payment?.note,
															})
														}
													>
														Show More
													</button>
												</>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</>
			)}

			<Modal isOpen={noteModal.isOpen} toggle={toggleNoteModal}>
				<ModalHeader toggle={toggleNoteModal}>Payment Note</ModalHeader>

				<ModalBody>
					<p>{noteModal.note}</p>
				</ModalBody>

				<ModalFooter>
					<button className="btn btn-primary" onClick={toggleNoteModal}>
						Close
					</button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
